$(document).ready(function() {

    var server_url = 'https://www.optimum-grupa.hr/';

	function isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    $("#poziv").submit(function(e) {
      var $form = $(this);
  			e.preventDefault();
  			$('.poziv').prop("disabled", true);
  			var name = $form.find("#name2").val();
  			var email = $form.find("#email2").val();
  			var phone = $form.find("#phone2").val();;
  			var message = $form.find("#message2").val();
			var izvor = $form.find("#izvor").val();
  			var dalje = true;
  			var errors = "";

  			if (name.length < 1) {
              dalje = false;
              $("#name2").css('border', '1px solid red');
  			} else {
  				$("#name2").css('border', '2px solid #e6ecef');
  			}
  			 if (!isEmail(email)) {
              dalje = false;
              $("#email2").css('border', '1px solid red');
              errors += "<p>Email adresa nije ispravna!</p>";
  			} else {
  				$("#email2").css('border', '2px solid #e6ecef');
  			}
  			if (phone.length < 1) {
              dalje = false;
              $("#phone2").css('border', '1px solid red');
  			} else {
  				$("#phone2").css('border', '2px solid #e6ecef');
  			}
  			if (message.length < 1) {
              dalje = false;
              $("#message2").css('border', '1px solid red');
  			} else {
  				$("#message2").css('border', '2px solid #e6ecef');
  			}
  			if(!dalje){
          $('.poziv').prop("disabled", false);
  				setTimeout(function() {
            $("#name2").css('border', '2px solid #e6ecef');
  					$("#phone2").css('border', '2px solid #e6ecef');
  					$("#message2").css('border', '2px solid #e6ecef');
  					$("#email2").css('border', '2px solid #e6ecef');
                      }, 2000);
  			}

  			if(dalje) {
  				var formData = new FormData(document.querySelector('#poziv'));
  				$.ajax({
  					url: server_url + 'scripts/kontakt.php',
  					type: 'POST',
  					data: formData,
  					success: function(result){
  						 if (result.error == 1) {
                          if (result.field) {
                              $("#" + result.field).css('border', '1px solid red');
                          }
                      }else{
                      window.location.replace(server_url + 'kontakt/potvrda/');
  					}
  					},
  					cache: false,
  					contentType: false,
  					processData: false
  				});
  			} else {
  				$('.poziv').prop("disabled", false);
  			}
  			return false;
  	});

    $("#upit").submit(function(e) {
      var $form = $(this);
  			e.preventDefault();
  			$('.poziv').prop("disabled", true);
  			var name = $form.find("#name3").val();
  			var email = $form.find("#email3").val();
  			var phone = $form.find("#phone3").val();;
  			var message = $form.find("#message3").val();
  			var dalje = true;
  			var errors = "";

  			if (name.length < 1) {
              dalje = false;
              $("#name3").css('border', '1px solid red');
  			} else {
  				$("#name3").css('border', '2px solid #e6ecef');
  			}
  			 if (!isEmail(email)) {
              dalje = false;
              $("#email3").css('border', '1px solid red');
              errors += "<p>Email adresa nije ispravna!</p>";
  			} else {
  				$("#email3").css('border', '2px solid #e6ecef');
  			}
  			if (phone.length < 1) {
              dalje = false;
              $("#phone3").css('border', '1px solid red');
  			} else {
  				$("#phone3").css('border', '2px solid #e6ecef');
  			}
  			if (message.length < 1) {
              dalje = false;
              $("#message3").css('border', '1px solid red');
  			} else {
  				$("#message3").css('border', '2px solid #e6ecef');
  			}
  			if(!dalje){
          $('.poziv').prop("disabled", false);
  				setTimeout(function() {
            $("#name3").css('border', '2px solid #e6ecef');
  					$("#phone3").css('border', '2px solid #e6ecef');
  					$("#message3").css('border', '2px solid #e6ecef');
  					$("#email3").css('border', '2px solid #e6ecef');
                      }, 2000);
  			}

  			if(dalje) {
  				var formData = new FormData(document.querySelector('#upit'));
  				$.ajax({
  					url: server_url + 'scripts/kontakt.php',
  					type: 'POST',
  					data: formData,
  					success: function(result){
  						 if (result.error == 1) {
                          if (result.field) {
                              $("#" + result.field).css('border', '1px solid red');
                          }
                      }else{
                      window.location.replace(server_url + 'kontakt/potvrda/');
  					}
  					},
  					cache: false,
  					contentType: false,
  					processData: false
  				});
  			} else {
  				$('.upit').prop("disabled", false);
  			}
  			return false;
  	});

	$("#kontakt").submit(function(e) {
    var $form = $(this);
			e.preventDefault();
			$('.kontakt').prop("disabled", true);
			var name = $form.find("#name").val();
			var email = $form.find("#email").val();
			var phone = $form.find("#phone").val();;
			var message = $form.find("#message").val();
			var izvor = $form.find("#izvor").val();
			var ugovor = $form.find("#ugovor").val();
			var staz = $form.find("#staz").val();
			var blokada = $form.find("#blokada").val();
			var dalje = true;
			var errors = "";

			if (name.length < 1) {
            dalje = false;
            $("#name").css('border', '1px solid red');
			} else {
				$("#name").css('border', '2px solid #e6ecef');
			}
			 if (!isEmail(email)) {
            dalje = false;
            $("#email").css('border', '1px solid red');
            errors += "<p>Email adresa nije ispravna!</p>";
			} else {
				$("#email").css('border', '2px solid #e6ecef');
			}
			if (phone.length < 1) {
            dalje = false;
            $("#phone").css('border', '1px solid red');
			} else {
				$("#phone").css('border', '2px solid #e6ecef');
			}
			if (staz.length < 1) {
            dalje = false;
            $("#staz").css('border', '1px solid red');
			} else {
				$("#staz").css('border', '2px solid #e6ecef');
			}
			if (message.length < 1) {
            dalje = false;
            $("#message").css('border', '1px solid red');
			} else {
				$("#message").css('border', '2px solid #e6ecef');
			}
			if(!dalje){
				setTimeout(function() {
                    $("#name").css('border', '2px solid #e6ecef');
					$("#phone").css('border', '2px solid #e6ecef');
					$("#message").css('border', '2px solid #e6ecef');
					$("#email").css('border', '2px solid #e6ecef');
					$("#staz").css('border', '2px solid #e6ecef');
                    }, 2000);
			}

			if(dalje) {
				var formData = new FormData(document.querySelector('#kontakt'));
				$.ajax({
					url: server_url + 'scripts/kontakt.php',
					type: 'POST',
					data: formData,
					success: function(result){
						 if (result.error == 1) {
                        if (result.field) {
                            $("#" + result.field).css('border', '1px solid red');
                        }
                    }else{
                    window.location.replace(server_url + 'kontakt/potvrda/');
					}
					},
					cache: false,
					contentType: false,
					processData: false
				});
			} else {
				$('.kontakt').prop("disabled", false);
			}
			return false;
	});

	$("#ocijeni").submit(function(e) {
      var $form = $(this);
  			e.preventDefault();
  			$('.ocijeni').prop("disabled", true);
  			var status_upiti = $form.find("#status_upiti").val();
			var razlog_upiti = $form.find("#razlog_upiti").val();
			var dodatno_upiti = $form.find("#dodatno_upiti").val();
			var id_upiti = $form.find("#id_upiti").val();

  			var dalje = true;
  			var errors = "";

  			if (status_upiti.length < 1) {
              dalje = false;
              $("#status_upiti").css('border', '1px solid red');
  			} else {
  				$("#status_upiti").css('border', '2px solid #e6ecef');
  			}

  			if (id_upiti.length < 1) {
              dalje = false;
              $("#id_upiti").css('border', '1px solid red');
  			} else {
  				$("#id_upiti").css('border', '2px solid #e6ecef');
  			}


  			if(!dalje){
          $('.ocijeni').prop("disabled", false);
  				setTimeout(function() {
						$("#status_upiti").css('border', '2px solid #e6ecef');
  				}, 2000);
  			}

  			if(dalje) {
  				var formData = new FormData(document.querySelector('#ocijeni'));
  				$.ajax({
  					url: server_url + 'scripts/ocijeni.php',
  					type: 'POST',
  					data: formData,
  					success: function(result){
  						 if (result.error == 1) {
                          if (result.field) {
                              $("#" + result.field).css('border', '1px solid red');
                          }
                      }else{
                      window.location.replace(server_url + 'ocjena/potvrda/?id=' + id_upiti + '&status=' + status_upiti);
  					}
  					},
  					cache: false,
  					contentType: false,
  					processData: false
  				});
  			} else {
  				$('.ocijeni').prop("disabled", false);
  			}
  			return false;
  	});

	$("#zatrazikontakt").submit(function(e) {
    var $form = $(this);
			e.preventDefault();
			$('.zatrazikontakt').prop("disabled", true);
			var name = $form.find("#name9").val();
			var email = $form.find("#email9").val();
			var phone = $form.find("#phone9").val();;
			var message = $form.find("#message9").val();
			var izvor = $form.find("#izvor").val();
			var ugovor = $form.find("#ugovor").val();
			var staz = $form.find("#staz").val();
			var blokada = $form.find("#blokada").val();
			var dalje = true;
			var errors = "";

			if (name.length < 1) {
            dalje = false;
            $("#name9").css('border', '1px solid red');
			} else {
				$("#name9").css('border', '2px solid #e6ecef');
			}
			 if (!isEmail(email)) {
            dalje = false;
            $("#email9").css('border', '1px solid red');
            errors += "<p>Email adresa nije ispravna!</p>";
			} else {
				$("#email9").css('border', '2px solid #e6ecef');
			}
			if (phone.length < 1) {
            dalje = false;
            $("#phone9").css('border', '1px solid red');
			} else {
				$("#phone9").css('border', '2px solid #e6ecef');
			}
			if (staz.length < 1) {
            dalje = false;
            $("#staz").css('border', '1px solid red');
			} else {
				$("#staz").css('border', '2px solid #e6ecef');
			}
			if (message.length < 1) {
            dalje = false;
            $("#message9").css('border', '1px solid red');
			} else {
				$("#message9").css('border', '2px solid #e6ecef');
			}
			if(!dalje){
				setTimeout(function() {
                    $("#name9").css('border', '2px solid #e6ecef');
					$("#phone9").css('border', '2px solid #e6ecef');
					$("#message9").css('border', '2px solid #e6ecef');
					$("#email9").css('border', '2px solid #e6ecef');
					$("#staz").css('border', '2px solid #e6ecef');
                    }, 2000);
			}

			if(dalje) {
				var formData = new FormData(document.querySelector('#zatrazikontakt'));
				$.ajax({
					url: server_url + 'scripts/kontakt.php',
					type: 'POST',
					data: formData,
					success: function(result){
						 if (result.error == 1) {
                        if (result.field) {
                            $("#" + result.field).css('border', '1px solid red');
                        }
                    }else{
                    window.location.replace(server_url + 'kontakt/potvrda/');
					}
					},
					cache: false,
					contentType: false,
					processData: false
				});
			} else {
				$('.zatrazikontakt').prop("disabled", false);
			}
			return false;
	});

  $("#predaj").submit(function(e) {
    var $form = $(this);
			e.preventDefault();
			$('.predaj').prop("disabled", true);
			var name = $form.find("#name").val();
			var email = $form.find("#email").val();
			var phone = $form.find("#phone").val();;
			var message = $form.find("#message").val();
      var iznos = $form.find("#iznos").val();
      var godine = $form.find("#godine").val();
      var mjeseci = $form.find("#mjeseci").val();
      var status = $form.find("#status").val();
      var blokirani = $form.find("#blokirani").val();
      var iznos_blokade = $form.find("#iznos_blokade").val();
			var dalje = true;
			var errors = "";

			if (name.length < 1) {
            dalje = false;
            $("#name").css('border', '1px solid red');
			} else {
				$("#name").css('border', '2px solid #e6ecef');
			}
			 if (!isEmail(email)) {
            dalje = false;
            $("#email").css('border', '1px solid red');
            errors += "<p>Email adresa nije ispravna!</p>";
			} else {
				$("#email").css('border', '2px solid #e6ecef');
			}
			if (phone.length < 1) {
            dalje = false;
            $("#phone").css('border', '1px solid red');
			} else {
				$("#phone").css('border', '2px solid #e6ecef');
			}

      if (iznos.length < 1) {
            dalje = false;
            $("#iznos").css('border', '1px solid red');
			} else {
				$("#iznos").css('border', '2px solid #e6ecef');
			}
      if (mjeseci.length < 1) {
            dalje = false;
            $("#mjeseci").css('border', '1px solid red');
			} else {
				$("#mjeseci").css('border', '2px solid #e6ecef');
			}
      if (godine.length < 1) {
            dalje = false;
            $("#godine").css('border', '1px solid red');
			} else {
				$("#godine").css('border', '2px solid #e6ecef');
			}
      if (status.length < 1) {
            dalje = false;
            $("#status").css('border', '1px solid red');
			} else {
				$("#status").css('border', '2px solid #e6ecef');
			}

			if (message.length < 1) {
            dalje = false;
            $("#message").css('border', '1px solid red');
			} else {
				$("#message").css('border', '2px solid #e6ecef');
			}

			if(!dalje){
				setTimeout(function() {
          $("#name").css('border', '2px solid #e6ecef');
					$("#phone").css('border', '2px solid #e6ecef');
					$("#message").css('border', '2px solid #e6ecef');
					$("#email").css('border', '2px solid #e6ecef');
          $("#status").css('border', '2px solid #e6ecef');
					$("#iznos").css('border', '2px solid #e6ecef');
					$("#godine").css('border', '2px solid #e6ecef');
					$("#mjeseci").css('border', '2px solid #e6ecef');
                    }, 2000);
			}

			if(dalje) {
				var formData = new FormData(document.querySelector('#predaj'));
				$.ajax({
					url: server_url + 'scripts/predaj.php',
					type: 'POST',
					data: formData,
					success: function(result){
						 if (result.error == 1) {
                        if (result.field) {
                            $("#" + result.field).css('border', '1px solid red');
                        }
                    }else{
                    window.location.replace(server_url + 'kontakt/potvrda/');
					}
					},
					cache: false,
					contentType: false,
					processData: false
				});
			} else {
				$('.predaj').prop("disabled", false);
			}
			return false;
	});

});
