/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

import $ from 'jquery';


// Import specific version of Bootstrap JS and CSS
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js';
import '@fontsource/lobster';
import '@fortawesome/fontawesome-free';
import 'bootstrap-icons/font/bootstrap-icons.css';

import '../public/build/js/main';
import '../public/build/js/bootstrap-4-navbar';
import '../public/build/js/magnific.min';
import '../public/build/js/isotope.pkgd.min';
import '../public/build/js/isotope.pkgd.min';
import '../public/build/js/jquery.meanmenu';
import '../public/build/js/form-validator.min';
import '../public/build/js/plugins';
import '../public/build/js/main';
import '../public/build/js/custom';
import '../public/build/js/jquery.stellar.min';
import '../public/build/js/jquery.accrue.min';



$('#step_three_form_creditType').on("change", function () {
     window.location.replace(window.location.origin + window.location.pathname + '?selectId=' + $(this).val())
});

$(".calculator-amortization").accrue({
     mode: "amortization"
});

$('[data-toggle="tooltip"]').tooltip();






