(function ($) {
    "use strict";

    $(document).ready(function() {
        /*---------------------
         TOP Menu Stick
        --------------------- */
        const windows = $(window);
        const sticky = $('#sticker');

        windows.on('scroll', function() {
            const scroll = windows.scrollTop();
            if (scroll < 300) {
                sticky.removeClass('stick');
            } else {
                sticky.addClass('stick');
            }
        });
    });
})(jQuery);
